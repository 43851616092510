import { Layout } from "antd";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

const { Footer, Content } = Layout;

export default function MainLayout({ children, setUser }) {
  return (
    <Layout>
      <Header setUser={setUser}></Header>
      <Layout>
        <Sidebar></Sidebar>
        <Content style={{ margin: "2rem" }}>{children}</Content>
      </Layout>
      <Footer style={{ background: "black" }}>Footer</Footer>
    </Layout>
  );
}
