import React from "react";
import { Typography, Button, Spin, Row, Tabs } from "antd";
import firebase from "firebase/app";
import { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import DetailProfileForm from "./detailProfielForm";
import MmseScores from "./MmseScores";
import moment from "moment";

const { Title } = Typography;
const { TabPane } = Tabs;
const UpdateProfile = () => {
  const { patientId } = useParams();
  const [isSuccesss, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientsData, setPatientsData] = useState([
    { name: "firstname", value: "" },
    { name: "lastname", value: "" },
    { name: "age", value: "" },
  ]);
  useEffect(() => {
    firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .get()
      .then((docRef) => {
        const newFields = Object.entries(docRef.data()).map(([key, value]) => {
          if (key === "dateOfBirth")
            return { name: key, value: new moment(value) };
          return { name: key, value };
        });
        setPatientsData(newFields);
        console.log(newFields);
      });
  }, [patientId]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .update(values)
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      });
  };

  if (isSuccesss) {
    return <Redirect to="/profile"></Redirect>;
  }
  return (
    <React.Fragment>
      {isLoading && <Spin></Spin>}
      <Link to={`/games/${patientId}`}>
        <Button>&#x2190;</Button>
      </Link>
      <Title level={2}>Detail Profile</Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Profile" key="1">
          <Row style={{ marginBottom: "0.5rem" }}>
            <Link to={`/profile/update/${patientId}`}>
              <Button type="primary">Edit</Button>
            </Link>
          </Row>
          <DetailProfileForm
            handleSubmit={handleSubmit}
            patientId={patientId}
            patientsData={patientsData}
          ></DetailProfileForm>
        </TabPane>
        <TabPane tab="MMSE" key="2">
          <MmseScores></MmseScores>
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};
export default UpdateProfile;
