import firebase from "firebase/app";
import { useEffect, useContext, useState, Fragment } from "react";
import { authContext } from "../../hooks/useUser";
import {
  List,
  Avatar,
  Card,
  Typography,
  Input,
  Button,
  Badge,
  Row,
} from "antd";
import { Link } from "react-router-dom";
const { Search } = Input;
const { Title } = Typography;
let db;
const filterNull = (arr) => {
  return arr.filter((val) => {
    return !!val.firstname;
  });
};

export default function Games({ user }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useContext(authContext);

  useEffect(() => {
    db = firebase.firestore();
    db.collection("users")
      .doc(auth.user.uid)
      .get()
      .then((docRef) => {
        // filterPatient = docRef.data().patients.filter((patientsRef) => {
        //   return patientsRef.get();
        // });

        return Promise.all(
          docRef.data().patients.map((patientsRef) => {
            return patientsRef.get().then((res) => {
              return { id: res.id, ...res.data() };
            });
          })
        );
      })
      .then((loadedPatients) => {
        console.log(filterNull(loadedPatients));
        setData(filterNull(loadedPatients));
      });
  }, [auth]);

  const onSearch = (value) => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("users")
      .doc(auth.user.uid)
      .get()
      .then((docRef) => {
        let { patients } = docRef.data();
        return Promise.all(patients.map((patientsRef) => patientsRef.get()));
      })
      .then((patients) => {
        patients = patients.map((patient) => patient.id);
        const patientsRef = db.collection("patients");
        if (value.trim() == "" || value == null) {
          return patientsRef.where("__name__", "in", patients).get();
        }
        return patientsRef
          .where("__name__", "in", patients)
          .where("firstname", "==", value)
          .get();
      })
      .then((loadedPatients) => {
        console.log(loadedPatients);
        if (loadedPatients.empty) {
          console.log("No matching documents.");
        }
        let loadedData = [];
        loadedPatients.forEach((element) => {
          loadedData.push({ id: element.id, ...element.data() });
        });
        setData(loadedData);
        setIsLoading(false);
      });
  };
  return (
    <Fragment>
      <Row justify="space-between">
        <Title level={2}>เลือกคนไข้ที่ต้องการ</Title>
        <Link to="/profile/create">
          <Button size="large" type="primary" shape="round">
            +
          </Button>
        </Link>
      </Row>
      <Search
        placeholder="input search text"
        enterButton="Search"
        onSearch={onSearch}
        loading={isLoading}
      ></Search>
      <p>{user}</p>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <Card>
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title={
                  <Link
                    to={`/games/${item.id}`}
                  >{`${item.firstname} ${item.lastname}`}</Link>
                }
                description={`${item.firstname} ${item.lastname} อายุ ${
                  2021 - new Date(item.dateOfBirth).getFullYear()
                } ปี`}
              />
              {item.isOnline && item.isOnline === true ? (
                <Badge size="default" color="green" text="Online"></Badge>
              ) : (
                <Badge size="default" color="red" text="Offline"></Badge>
              )}
            </List.Item>
          </Card>
        )}
      />
    </Fragment>
  );
}
