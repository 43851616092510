import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import initAuth from "./initAuth";
import Login from "./pages/login";
import Games from "./pages/games/index";
import Profile from "./pages/profile";
import Records from "./components/Records";
import Dashboard from "./pages/dashboard";
import MainLayout from "./layout/Main";
import LoginLayout from "./layout/Login";
import CreateProfile from "./components/newProfile";
import UpdateProfile from "./components/updateProfile";
import DetailProfile from "./components/detailProfile";
import GameController from "./components/GameController";
import MmseScores from "./components/MmseScores";
import WS from "./components/wsClient";
import VideoPlayer from "./components/videosPlayer/VideoPlayer";
import { useContext } from "react";
import { ProvideAuth, authContext } from "./hooks/useUser";


initAuth();

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <Redirect to="/games"></Redirect>,
  },
  {
    path: "/games",
    exact: true,
    main: () => <Games></Games>,
  },
  {
    path: "/games/:patientId",
    main: () => <GameController></GameController>,
  },
  {
    path: "/profile",
    exact: true,
    main: () => <Profile></Profile>,
  },
  {
    path: "/profile/create",
    exact: true,
    main: () => <CreateProfile></CreateProfile>,
  },
  {
    path: "/profile/update/:patientId",
    main: () => <UpdateProfile></UpdateProfile>,
  },
  {
    path: "/profile/detail/:patientId",
    main: () => <DetailProfile></DetailProfile>,
  },
  {
    path: "/records/:patientId",
    main: () => <Records></Records>,
  },
  {
    path: "/dashboard",
    main: () => <Dashboard></Dashboard>,
  },
  {
    path: "/mmse-score/:patientId",
    main: () => <MmseScores></MmseScores>,
  },
  {
    path: "/ws",
    main: () => <WS></WS>,
  },
  {
    path: "/stream",
    main: () => <VideoPlayer></VideoPlayer>,
  },
];

const PrivateRoute = ({ component: Component, ...rest }) => {
  let auth = useContext(authContext);
  const isLoggedIn = auth.user != null;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

function App() {
  return (
    <ProvideAuth>
      <Switch>
        <Route path="/login">
          <LoginLayout>
            <Login />
          </LoginLayout>
        </Route>
        <MainLayout>
          {routes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={() => <route.main />}
            />
          ))}
        </MainLayout>
      </Switch>
    </ProvideAuth>
  );
}

export default App;
