export async function getServerConfig() {
  // const protocolEndPoint = "http://localhost:8080" + "/config";
  // const createResponse = await fetch(protocolEndPoint);
  // console.log(createResponse)
  // return await createResponse.json();
  return { useWebSocket: true, startupMode: "public", logging: "dev" };
}

export function getRTCConfiguration() {
  let config = {};
  config.sdpSemantics = "unified-plan";
  config.iceServers = [{ urls: ["stun:stun.l.google.com:19302"] }];
  return config;
}
