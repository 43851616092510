import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useParams, Link } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  InputNumber,
  Button,
  Table,
  Timeline,
  Popover,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import win_time from "windows-timestamp";

const Records = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const { patientId } = useParams();
  const [scores, setScores] = useState([]);

  const handleAddMMSEScore = ({ score }) => {
    console.log(score);
    firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .collection("mmseScores")
      .add({ score, createdAt: Date.now() });
  };
  useEffect(() => {
    return firebase
      .firestore()
      .collection("scores")
      .doc(patientId)
      .collection("records")
      .get()
      .then((snapshot) => {
        let ans = [];
        snapshot.forEach((doc) => {
          ans.push({ id: doc.id, ...doc.data() });
        });
        // ans.sort((a, b) => a.timestamp - b.timeStart);
        setScores(ans);
      });
  }, []);

  const mapColor = (actionId) => {
    switch (actionId) {
      case 0:
        return "blue";
        break;
      case 1:
        return "red";
        break;
      case 2:
        return "yellow";
        break;
      case 3:
        return "green";
        break;
      default:
        return "blue";
        break;
    }
  };

  const mapAction = (actionId) => {
    switch (actionId) {
      case 0:
        return "Correct";
        break;
      case 1:
        return "Wrong";
        break;
      case 2:
        return "Hint";
        break;
      case 3:
        return "Finish";
        break;
      default:
        return "action";
        break;
    }
  };
  const dateFromWinTimstamp = (winTimestamp) => {
    const utc = win_time(winTimestamp);
    return `${utc.toLocaleString("th-TH", {
      timeZone: "Asia/Bangkok",
    })}`;
  };
  const content = (record) => (
    <Timeline>
      {record.timeline &&
        record.timeline.map(({ timestamp, action }) => (
          <Timeline.Item
            key={timestamp + action.type}
            color={mapColor(action.type)}
          >
            {`${dateFromWinTimstamp(timestamp)} ${mapAction(action.type)}`}
          </Timeline.Item>
        ))}
    </Timeline>
  );

  const filteredScore = (scores) => {
    return scores.map((score) => {
      const eventCount = score.timeline.length - 1;
      const correct = score.timeline.filter((v) => v.action.type == 0).length;
      const wrong = score.timeline.filter((v) => v.action.type == 1).length;
      const hintUsage = score.timeline.filter((v) => v.action.type == 2).length;

      const diff_minutes = (dt2, dt1) => {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff = Math.floor(Math.abs(diff));
        const diffMin = Math.floor(diff / 60);
        const diffSec = diff - diffMin * 60;
        console.log(diffSec, diff, diffMin);
        if (diffMin == 0) {
          return `${diffSec} วินาที`;
        }
        return `${diffMin} นาที ${diffSec} วินาที`;
      };
      let totalTime = "-";
      if (
        score.timeline.length > 0 &&
        score.timeline[score.timeline.length - 1].action.type == 3
      ) {
        totalTime = diff_minutes(
          win_time(score.startTimestamp),
          win_time(score.timeline[score.timeline.length - 1].timestamp)
        );
      }

      return {
        ...score,
        eventCount,
        hintUsage,
        correct,
        wrong,
        totalTime,
      };
    });
  };
  const mapSceneName = {
    0: "Checklist",
    1: "Arrange",
    2: "Checkout",
    3: "Distribute",
  };
  return (
    <div>
      <Link to={`/games/${patientId}`}>
        <Button>&#x2190;</Button>
      </Link>
      <Table
        pagination={{ position: ["topRight"], simple: true, pageSize: 10 }}
        scroll={{ x: 100 }}
        rowKey="id"
        columns={[
          {
            title: "Created At",
            dataIndex: "startTimestamp",
            defaultSortOrder: "descend",
            sorter: (a, b) => {
              return a.startTimestamp - b.startTimestamp;
            },
            render: (text) => {
              return dateFromWinTimstamp(text);
            },
          },
          {
            title: "Game",
            render: (text, record) => {
              const { gameId } = record;
              return `${mapSceneName[gameId]}`;
            },
            filters: [
              {
                text: "Checklist",
                value: "0",
              },
              {
                text: "Arrange",
                value: "1",
              },
              {
                text: "Checkout",
                value: "2",
              },
              {
                text: "Distribute",
                value: "3",
              },
            ],
            onFilter: (value, record) => {
              return record.gameId === value;
            },
          },
          {
            title: "Level-Step",
            render: (text, record) => {
              const { level, step } = record;
              return `${parseInt(level)}-${parseInt(step) + 1}`;
            },
          },
          {
            title: "EventCount",
            dataIndex: "eventCount",
          },
          {
            title: "Correct",
            dataIndex: "correct",
          },
          {
            title: "Wrong",
            dataIndex: "wrong",
          },
          {
            title: "Hint Usage",
            dataIndex: "hintUsage",
          },

          {
            title: "Total Time",
            dataIndex: "totalTime",
          },

          {
            render: (text, record) => (
              <Popover
                content={content(record)}
                title="Timeline"
                trigger="click"
              >
                <Button>Timeline</Button>
              </Popover>
            ),
          },
        ]}
        dataSource={filteredScore(scores)}
      ></Table>
    </div>
  );
};

export default Records;
