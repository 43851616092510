import { List } from "antd";
import React, { useEffect, useState } from "react";
const WsClient = () => {
  const [ws, setWs] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    const ws = new WebSocket("ws://192.168.1.112:8080");
    ws.onopen = () => {
      console.log("connected");
    };
    ws.onmessage = (evt) => {
      setData((prevData) => {
        prevData.push(evt.data);
        console.log(prevData);
        return prevData;
      });
    };
    setWs(ws);
    return () => {
      console.log("close");
      ws.close();
    };
  }, []);

  return (
    <React.Fragment>
      <List>
        {data.map((e) => (
          <List.Item>{e}</List.Item>
        ))}
      </List>
    </React.Fragment>
  );
};

export default WsClient;
