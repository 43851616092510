import { Typography, List, Card, Avatar, Row, Button, Form, Input } from "antd";
import firebase from "firebase/app";
import { useEffect, useContext, useState, Fragment } from "react";
import { authContext } from "../hooks/useUser";
import { Link } from "react-router-dom";
const { Title } = Typography;

export default function Games() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const auth = useContext(authContext);
  const checkPassword = (password, confirmPassword) => {
    return password === confirmPassword;
  };
  const handleSubmit = ({ username, password, confirmPassword }) => {
    if (!checkPassword(password, confirmPassword)) {
      return;
    }
    setIsLoading(true);
    let newDocRef;
    const newForm = { username, password };
    firebase
      .firestore()
      .collection("patients")
      .add(newForm)
      // .then((docRef) => {
      //   newDocRef = docRef;
      //   return firebase
      //     .firestore()
      //     .collection("users")
      //     .doc(auth.user.uid)
      //     .get();
      // })
      // .then((docSnapshot) => {
      //   const { patients } = docSnapshot.data();
      //   return firebase
      //     .firestore()
      //     .collection("users")
      //     .doc(auth.user.uid)
      //     .update({ patients: [...patients, newDocRef] });
      // })
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      });
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .doc(auth.user.uid)
      .get()
      .then((docRef) => {
        return Promise.all(
          docRef.data().patients.map((patientsRef) => {
            return patientsRef.get().then((res) => {
              return { id: res.id, ...res.data() };
            });
          })
        );
      })
      .then((loadedPatients) => {
        console.log(loadedPatients);
        setData(loadedPatients);
      });
  }, [auth]);

  return (
    <Fragment>
      <Title level={2}>Register</Title>
      <Row>
        <Link to="/profile/create">
          <Button>+</Button>
        </Link>
      </Row>
      <Card>
        <Form onFinish={handleSubmit}>
          <Form.Item
            label="username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input placeholder="username" />
          </Form.Item>
          <Form.Item
            label="password"
            name="password"
            rules={[{ message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please input your confirm password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Row justify="space-between">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </Form>
      </Card>
    </Fragment>
  );
}
