import Signaling, { WebSocketSignaling } from "./signaling.js";
import * as Config from "./config";
import * as Logger from "./logger.js";
import React, { useEffect, useState, createRef } from "react";
import { getServerConfig } from "../videosPlayer/config";
import { Button, Row, Typography } from "antd";
import { VideoPlayer } from "./video-player";
// import main from "./main/main";
const ENUM = {};
const { Text } = Typography;
// enum type of event sending from Unity
var UnityEventType = {
  SWITCH_VIDEO: 0,
};

function uuid4() {
  var temp_url = URL.createObjectURL(new Blob());
  var uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  return uuid.split(/[:\/]/g).pop().toLowerCase(); // remove prefixes
}

const MyVideoPlayer = ({ handleDisconnected, message, patientId }) => {
  const ref = React.createRef();
  const VideoThumb = <video id="VideoThumbnail"></video>;

  let useWebSocket;
  const videoRef = createRef();

  // if (videoRef.current && !videoRef.current.srcObject) {
  //   videoRef.current.srcObject = vp.localStream;
  // }
  const [isConnected, setIsConnected] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const location = {
    protocol: "http://",
    host: "18.140.218.89:8080",
    url: patientId,
  };
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [vp, setVp] = useState(new VideoPlayer([videoRef, ref, location]));
  // const vp = new VideoPlayer([videoRef, ref, location])
  const play = () => {
    videoRef.current.srcObject = vp.localStream;
    videoRef.current.play().then(() => {
      vp.resizeVideo();
    });
  };
  vp.addEventListener("disconnectTovideo", () => {
    console.log("disconnectTovideo");
  });

  vp.addEventListener("connectTovideo", () => {
    console.log(videoRef);
    if (videoRef.current === null) {
      return;
    }
    videoRef.current.srcObject = vp.localStream;
    videoRef.current.play();
    // setShowPlayButton(false);
    console.log("connectTovideo");
  });
  // const handleForceDisconnect = () => {
  //   vp.signaling.forceDisconnect();
  // };

  // const handleForceRestart = () => {
  //   vp.stop();
  // };

  // const handleForceStop = () => {
  //   vp.stop();
  // };

  useEffect(() => {
    // console.log("message send");
    // if (vp.channel === null) return;
    // console.log(vp.channel.readyState);
    // if (vp.channel.readyState !== "open") return;
    if (!message) return;
    let data = new DataView(new ArrayBuffer(3));
    const tmp = [0, 0, 0];
    switch (message.command) {
      case "changeRoom":
        tmp[0] = 5;
        tmp[1] = parseInt(message.roomId);
        break;
      case "changeGame":
        tmp[0] = 0;
        tmp[1] = parseInt(message.gameId);
        break;
      case "changeStage":
        tmp[0] = 1;
        tmp[1] = parseInt(message.level);
        tmp[2] = parseInt(message.subLevel);
        break;
      case "showHint":
        tmp[0] = 2;
        break;
      case "backToLobby":
        tmp[0] = 3;
        break;
      case "replay":
        tmp[0] = 4;
        break;
    }
    data.setUint8(0, tmp[0]);
    data.setUint8(1, tmp[1]);
    data.setUint8(2, tmp[2]);
    // console.log(tmp);
    // videoPlayer && videoPlayer.sendMsg(data.buffer);
    vp.sendMsg(data.buffer);
    // console.log(data.buffer);
  }, [message]);

  useEffect(() => {
    getServerConfig()
      .then((res) => {
        useWebSocket = res.useWebSocket;
        vp.setupConnection(useWebSocket);
        return vp.signaling.start();
      })
      .then(() => {
        console.log("finished setup");
        setIsConnected(true);
        console.log(vp);
        // setVp(newVp)
        const v = document.getElementById("Video");
        // console.log(v)
        // v.srcObject = vp.localStream
      });
  }, []);

  // useEffect( () => {
  //   getServerConfig()
  //   .then((res) => {
  //     console.log("OK");
  //     useWebSocket = res.useWebSocket;
  //     return vp.setupConnection(useWebSocket);
  //   }).then( ()=>{
  //     console.log("finished resetup");
  //     console.log(vp);
  //     // setVp(newVp)
  //     const v = document.getElementById("Video");
  //   })

  // } , [vp] )

  return (
    <React.Fragment>
      <div
        id="player"
        style={{ width: "100%", height: "75vh", maxWidth: "700px" }}
      >
        <video
          ref={videoRef}
          controls
          controlsList="nodownload noremoteplayback"
          muted
          playsInline
          onLoadedMetadata={() => {
            console.log("okay");
            // videoRef.current.srcObject = vp.localStream;
            // videoRef.current.srcObject = vp.localStream;
            // videoRef.current.play();
          }}
          id="Video"
        ></video>
        {showPlayButton && (
          <img
            id="playButton"
            src="images/Play.png"
            alt="Start Streaming"
            style={{ zIndex: 1 }}
            onClick={() => {
              videoRef.current.srcObject = vp.localStream;
              videoRef.current.play();
              setShowPlayButton(false);
            }}
          ></img>
        )}
      </div>
      {/* <button
        onClick={() => {
          handleForceDisconnect();
        }}
      >
        Force restart
      </button>
      <button
        onClick={() => {
          handleForceRestart();
        }}
      >
        Force stop
      </button>
      <button
        onClick={() => {
          handleForceStop();
        }}
      >
        Force disconnect
      </button> */}
    </React.Fragment>
  );
};
export default MyVideoPlayer;
