import { useState, createContext } from "react";
function useProvideAuth() {
  const [user, setUser] = useState({ uid: "OX1cGPfH0ChB4pevnteadpaUHNA3" });

  const signin = (user) => {
    setUser(user);
  };

  const signout = () => {
    setUser(null);
  };

  return {
    user,
    signin,
    signout,
  };
}
export const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
