import { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useParams, Link } from "react-router-dom";
import { Form, Row, Modal, InputNumber, Button, Table, Popconfirm } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const MmseScores = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRecordsModalVisible, setIsRecordsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [scores, setScores] = useState([]);
  const { patientId } = useParams();

  const handleAddMMSEScore = (score) => {
    return firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .collection("mmseScores")
      .add({ score, createdAt: Date.now() })
      .then(() => setIsLoading(true));
  };
  useEffect(() => {
    if (!isLoading) {
      return;
    }
    return firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .collection("mmseScores")
      .get()
      .then((snapshot) => {
        let ans = [];
        snapshot.forEach((doc) => {
          ans.push({ id: doc.id, ...doc.data(), key: doc.id });
        });
        ans.sort((a, b) => b.createdAt - a.createdAt);
        setData(ans);
        setIsLoading(false);
      });
  }, [isLoading]);
  useEffect(() => {
    return firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .collection("mmseScores")
      .get()
      .then((snapshot) => {
        let ans = [];
        snapshot.forEach((doc) => {
          ans.push({ id: doc.id, ...doc.data(), key: doc.id });
        });
        ans.sort((a, b) => b.createdAt - a.createdAt);
        setData(ans);
      });
  }, []);
  const showPropsConfirm = () => {
    setIsLoading(true);
    const score = form.getFieldValue("score");
    form.resetFields();
    return handleAddMMSEScore(score).then(() => setIsLoading(false));
  };
  return (
    <div>
      <Form form={form} onFinish={showPropsConfirm}>
        <Row style={{ width: "100%", marginBottom: "-2rem", zIndex: "2" }}>
          <Row>
            <Form.Item
              label="score"
              name="score"
              rules={[
                {
                  required: true,
                  message: "Please input your score!",
                },
              ]}
            >
              <InputNumber></InputNumber>
            </Form.Item>
          </Row>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Add
          </Button>
          <Modal
            title="Are you sure to send score?"
            visible={isModalVisible}
            onOk={showPropsConfirm}
          ></Modal>
        </Row>
      </Form>

      <Table
        // style={{ maxHeight: "50vh" }}
        pagination={{ position: ["topRight"], simple: true, pageSize: 5 }}
        columns={[
          { title: "Score", dataIndex: "score" },
          {
            title: "Created At",
            render: (text) => {
              const d = new Date(text.createdAt);
              // d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
              return `${d.toLocaleString()}`;
            },
          },
        ]}
        dataSource={data}
      ></Table>
    </div>
  );
};

export default MmseScores;
