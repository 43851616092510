// ./initAuth.js
import firebase from "firebase";
import serviceAccount from "./serviceAccount.json";

const initAuth = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      authPageURL: "/login",
      appPageURL: "/",
      loginAPIEndpoint: "/api/login", // required
      logoutAPIEndpoint: "/api/logout", // required
      apiKey: "AIzaSyC9yMLiNfAEUMySv83P4ee1bQ42zuVVlw4", // required
      authDomain: "endox-web.firebaseapp.com",
      databaseURL: "https://endox-web-default-rtdb.firebaseio.com",
      projectId: serviceAccount.project_id,
      // firebaseAuthEmulatorHost: "localhost:9099",
      // Required in most cases.
      // firebaseAdminInitConfig: {
      //   credential: serviceAccount,
      //   databaseURL: "https://endox-web-default-rtdb.firebaseio.com",
      // },
      // firebaseClientInitConfig: {
      //   apiKey: "AIzaSyC9yMLiNfAEUMySv83P4ee1bQ42zuVVlw4", // required
      //   authDomain: "endox-web.firebaseapp.com",
      //   databaseURL: "https://endox-web-default-rtdb.firebaseio.com",
      //   projectId: serviceAccount.project_id,
      // },
      cookies: {
        name: "test", // required
        // Keys are required unless you set `signed` to `false`.
        // The keys cannot be accessible on the client side.
        keys: [
          process.env.COOKIE_SECRET_CURRENT,
          process.env.COOKIE_SECRET_PREVIOUS,
        ],
        httpOnly: true,
        maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
        overwrite: true,
        path: "/",
        sameSite: "strict",
        secure: true, // set this to false in local (non-HTTPS) development
        signed: true,
      },
    });
  } else {
    firebase.app(); // if already initialized, use that one
  }
};

export default initAuth;
