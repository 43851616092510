import React from "react";
import {
  Row,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Spin,
  DatePicker,
  Modal,
  Select,
} from "antd";
import firebase from "firebase/app";
import { useState, useEffect } from "react";
import { Link, Redirect, useParams, Prompt } from "react-router-dom";
import moment from "moment";
const { Option } = Select;
const educations = ["below bechelor", "bachelor", " master", "doctor"];
const { confirm } = Modal;
const { Title } = Typography;
const UpdateProfile = () => {
  const { patientId } = useParams();
  const [isSuccesss, setIsSuccess] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [patientsData, setPatientsData] = useState([
    { name: "firstname", value: "" },
    { name: "lastname", value: "" },
    { name: "idNumber", value: "" },
    { name: "dateOfBirth", value: "" },
  ]);

  const handleSubmit = (values) => {
    console.log(values);
    values.dateOfBirth = values.dateOfBirth.format("YYYY-MM-DD");
    const filterUndefined = {};
    Object.keys(values).map((key) => {
      if (values[key]) filterUndefined[key] = values[key];
    });
    setIsLoading(true);
    firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .update(filterUndefined)
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      });
  };

  const showConfirm = () => {
    confirm({
      title: "Do you Want to delete these items?",

      content: "Some descriptions",
      onOk() {
        setIsSuccess(true);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .get()
      .then((docRef) => {
        const newFields = Object.entries(docRef.data()).map(([key, value]) => {
          if (key === "dateOfBirth")
            return { name: key, value: new moment(value) };
          return { name: key, value };
        });
        setPatientsData(newFields);
      });
  }, [patientId]);

  // useEffect(() => {
  //   return () => {
  //     // && history.location.pathname === "any specific path")

  //     if (history.action === "POP") {
  //       showConfirm();
  //       setIsSuccess(true);
  //     }
  //   };
  // }, [history]);

  if (isSuccesss) {
    return <Redirect to={`/profile/detail/${patientId}`}></Redirect>;
  }
  return (
    <React.Fragment>
      {isLoading && <Spin></Spin>}
      <Prompt
        when={isBlocking}
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
      <Title level={2}>Update Profile</Title>
      <Card>
        <Form
          onFinish={handleSubmit}
          fields={patientsData}
          onChange={() => setIsBlocking(true)}
        >
          <Form.Item
            label="Firstname"
            name="firstname"
            rules={[
              { required: true, message: "Please input your firstname!" },
            ]}
          >
            <Input placeholder="Firstname" />
          </Form.Item>
          <Form.Item
            label="Lastname"
            name="lastname"
            rules={[
              { required: true, message: "Please input your firstname!" },
            ]}
          >
            <Input
              placeholder="Lastname"
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Date Of Birth"
            name="dateOfBirth"
            rules={[
              { required: true, message: "Please input your date of birth!" },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Identification Number"
            name="identificationNumber"
            rules={[
              {
                required: true,
                message: "Please input your identificationNumber!",
                len: 13,
              },
            ]}
          >
            <Input maxLength={13} placeholder="xxxxxxxxxxxxx" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: "Please input your age!", len: 10 },
            ]}
          >
            <Input maxLength={10} placeholder="phoneNumber" />
          </Form.Item>
          <Form.Item
            name="education"
            label="Education"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select a option" allowClear>
              {educations.map((education) => {
                return <Option value={education}>{education}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input.TextArea placeholder="address" />
          </Form.Item>
          <h2>Contact Person</h2>
          <Form.Item
            label="Firstname"
            name="contactPersonFirstname"
            rules={[
              { required: true, message: "Please input your firstname!" },
            ]}
          >
            <Input
              placeholder="Firstname"
              rules={[
                { required: true, message: "Please input your firstname!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Lastname"
            name="contactPersonLastname"
            rules={[{ required: true, message: "Please input your lastname!" }]}
          >
            <Input
              placeholder="Lastname"
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="contactPersonPhoneNumber"
            rules={[
              { required: true, message: "Please input your age!", len: 10 },
            ]}
          >
            <Input maxLength={10} placeholder="phoneNumber" />
          </Form.Item>
          <Row justify="space-between">
            <Link to={`/profile/detail/${patientId}`}>
              <Button type="secondary" htmlType="button">
                Cancel
              </Button>
            </Link>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </Form>
      </Card>
    </React.Fragment>
  );
};
export default UpdateProfile;
