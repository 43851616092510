import React from "react";
import {
  Card,
  Typography,
  Form,
  Input,
  Button,
  Spin,
  DatePicker,
  Select,
} from "antd";
import firebase from "firebase/app";
import { useContext, useState } from "react";
import { authContext } from "../hooks/useUser";
import { Redirect } from "react-router-dom";
import moment from "moment";

const { Title } = Typography;
const { Option } = Select;
const educations = ["below bechelor", "bachelor", " master", "doctor"];

const CreateProfile = () => {
  const [isSuccesss, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useContext(authContext);

  const handleSubmit = (values) => {
    setIsLoading(true);
    values.dateOfBirth = values.dateOfBirth.format("YYYY-MM-DD");
    console.log(values);
    const filterUndefined = {};
    Object.keys(values).map((key) => {
      if (values[key]) filterUndefined[key] = values[key];
    });
    let newDocRef;
    firebase
      .firestore()
      .collection("patients")
      .add(filterUndefined)
      .then((docRef) => {
        newDocRef = docRef;
        return firebase
          .firestore()
          .collection("users")
          .doc(auth.user.uid)
          .get();
      })
      .then((docSnapshot) => {
        const { patients } = docSnapshot.data();
        return firebase
          .firestore()
          .collection("users")
          .doc(auth.user.uid)
          .update({ patients: [...patients, newDocRef] });
      })
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      });
  };
  if (isSuccesss) {
    return <Redirect to="/games"></Redirect>;
  }
  return (
    <React.Fragment>
      {isLoading && <Spin></Spin>}
      <Title level={2}>New Profile</Title>
      <Card>
        <Form onFinish={handleSubmit}>
          <Form.Item
            label="Firstname"
            name="firstname"
            rules={[
              { required: true, message: "Please input your firstname!" },
            ]}
          >
            <Input placeholder="Firstname" />
          </Form.Item>
          <Form.Item
            label="Middlename"
            name="middlename"
            rules={[{ message: "Please input your middlename!" }]}
          >
            <Input
              placeholder="Lastname"
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Lastname"
            name="lastname"
            rules={[{ required: true, message: "Please input your lastname!" }]}
          >
            <Input
              placeholder="Lastname"
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name="dateOfBirth"
            rules={[
              { required: true, message: "Please input your date of birth!" },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Identification Number"
            name="identificationNumber"
            rules={[
              {
                required: true,
                message: "Please input your identification number!",
                len: 13,
              },
            ]}
          >
            <Input maxLength={13} placeholder="xxxxxxxxxxxxx" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
                len: 10,
              },
            ]}
          >
            <Input maxLength={10} placeholder="phoneNumber" />
          </Form.Item>
          <Form.Item
            name="education"
            label="Education"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select a option" allowClear>
              {educations.map((education) => {
                return <Option value={education}>{education}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input.TextArea placeholder="address" />
          </Form.Item>
          <h2>Contact Person</h2>
          <Form.Item
            label="Firstname"
            name="contactPersonFirstname"
            rules={[
              { required: true, message: "Please input your firstname!" },
            ]}
          >
            <Input placeholder="Firstname" />
          </Form.Item>

          <Form.Item
            label="Middlename"
            name="contactPersonMiddlename"
            rules={[{ message: "Please input your middlename!" }]}
          >
            <Input
              placeholder="MiddleName"
              rules={[
                { required: true, message: "Please input your middleName!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Lastname"
            name="contactPersonLastname"
            rules={[{ required: true, message: "Please input your lastname!" }]}
          >
            <Input
              placeholder="Lastname"
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="contactPersonPhoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
                len: 10,
              },
            ]}
          >
            <Input maxLength={10} placeholder="phoneNumber" />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Card>
    </React.Fragment>
  );
};
export default CreateProfile;
