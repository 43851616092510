import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  ControlOutlined,
  UserOutlined,
  AuditOutlined,
  DashboardOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const Sidebar = () => {
  return (
    <Sider
      breakpoint="md"
      collapsedWidth="0"
      style={{ background: "white", minHeight: "95vh" }}
    >
      <Menu mode="inline" theme="light" defaultSelectedKeys={["/games"]}>
        <Menu.Item key="/games" icon={<ControlOutlined />}>
          <Link to="/games">เล่นเกมส์</Link>
        </Menu.Item>
        {/* <Menu.Item key="/profile" icon={<UserOutlined />}>
          <Link to="/profile">ข้อมูลผู้ใช้</Link>
        </Menu.Item> */}
        {/* <Menu.Item key="/records" icon={<AuditOutlined />}>
          <Link to="/records">สถิติการเล่นย้อนหลัง</Link>
        </Menu.Item> */}
        <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
          <Link to="/dashboard">กระดานสรุปผล</Link>
        </Menu.Item>
        {/* <Menu.Item key="/ws" icon={<DashboardOutlined />}>
          <Link to="/ws">Websocket</Link>
        </Menu.Item>
        <Menu.Item key="/stream" icon={<DashboardOutlined />}>
          <Link to="/stream">Stream</Link>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
