import { Col, Typography, Card, Row, Button } from "antd";
import {
  PauseCircleOutlined,
  UserOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
const { Title } = Typography;
const HelperSelector = ({
  handleBackToLobby,
  handleShowHint,
  handleReplay,
}) => {
  return (
    <Col span={24}>
      <Title level={2}>เลือกความช่วยเหลือที่ต้องการ</Title>
      <Card>
        <Row justify="space-between" style={{ flexDirection: "column" }}>
          {/* <Button
              style={{ margin: ".1rem 0" }}
              size="large"
              icon={<PauseCircleOutlined></PauseCircleOutlined>}
              onClick={handleBackToLobby}
            >
               กลับหน้าล็อบบี้
            </Button> */}
          <Button
            style={{ margin: ".1rem 0" }}
            size="large"
            icon={<UserOutlined></UserOutlined>}
            onClick={handleShowHint}
          >
            ใช้ตัวช่วย
          </Button>
          <Button
            style={{ margin: ".1rem 0" }}
            size="large"
            icon={<RotateRightOutlined></RotateRightOutlined>}
            onClick={handleReplay}
          >
            เล่นซ้ำ
          </Button>
        </Row>
      </Card>
    </Col>
  );
};

export default HelperSelector;
