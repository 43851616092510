import * as Logger from "./logger.js";

export class WebSocketSignaling extends EventTarget {
  constructor(location) {
    super();
    this.sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));

    let websocketUrl;
    if (location.protocol === "https:") {
      websocketUrl = "wss://" + location.host;
    } else {
      websocketUrl = "ws://" + location.host + "/" + location.url + "/client";
    }
    this.websocketUrl = websocketUrl;
    this.connectionId = null;
    this.websocket = new WebSocket(this.websocketUrl);
    // this.createWebSocket.bind(this);
    // this.createWebSocket(this.websocketUrl)
    this.websocket.onopen = () => {
      this.isWsOpen = true;
    };

    this.websocket.onclose = () => {
      this.isWsOpen = false;
    };

    this.websocket.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      if (!msg || !this) {
        return;
      }

      Logger.log(msg);

      switch (msg.type) {
        case "connect":
          this.dispatchEvent(new CustomEvent("connect", { detail: msg }));
          break;
        case "disconnect":
          this.dispatchEvent(new CustomEvent("disconnect", { detail: msg }));
          break;
        case "offer":
          this.dispatchEvent(
            new CustomEvent("offer", {
              detail: {
                connectionId: msg.from,
                sdp: msg.data.sdp,
                polite: msg.data.polite,
              },
            })
          );
          break;
        case "answer":
          this.dispatchEvent(
            new CustomEvent("answer", {
              detail: { connectionId: msg.from, sdp: msg.data.sdp },
            })
          );
          break;
        case "candidate":
          this.dispatchEvent(
            new CustomEvent("candidate", {
              detail: {
                connectionId: msg.from,
                candidate: msg.data.candidate,
                sdpMLineIndex: msg.data.sdpMLineIndex,
                sdpMid: msg.data.sdpMid,
              },
            })
          );
          break;
        default:
          break;
      }
    };
  }

  forceDisconnect() {
    console.log("In signaling")
    this.dispatchEvent(new CustomEvent("disconnect"));
  }

  createWebSocket(websocketUrl) {
    const websocket = new WebSocket(websocketUrl);
    const _this = this;
    websocket.onopen = () => {
      console.log("Web socket open success")
      _this.isWsOpen = true;
    };

    websocket.onclose = () => {
      _this.isWsOpen = false;
    };

    websocket.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      if (!msg || !_this) {
        return;
      }

      Logger.log(msg);

      switch (msg.type) {
        case "connect":
          _this.dispatchEvent(new CustomEvent("connect", { detail: msg }));
          break;
        case "disconnect":
          _this.dispatchEvent(new CustomEvent("disconnect", { detail: msg }));
          break;
        case "offer":
          _this.dispatchEvent(
            new CustomEvent("offer", {
              detail: {
                connectionId: msg.from,
                sdp: msg.data.sdp,
                polite: msg.data.polite,
              },
            })
          );
          break;
        case "answer":
          _this.dispatchEvent(
            new CustomEvent("answer", {
              detail: { connectionId: msg.from, sdp: msg.data.sdp },
            })
          );
          break;
        case "candidate":
          _this.dispatchEvent(
            new CustomEvent("candidate", {
              detail: {
                connectionId: msg.from,
                candidate: msg.data.candidate,
                sdpMLineIndex: msg.data.sdpMLineIndex,
                sdpMid: msg.data.sdpMid,
              },
            })
          );
          break;
        default:
          break;
      }
    };
    _this.websocket = websocket;
    return;
  }
  get interval() {
    return 100;
  }

  async start() {
    while (!this.isWsOpen) {
      await this.sleep(100);
    }
  }

  async stop() {
    this.websocket.close();
    while (this.isWsOpen) {
      await this.sleep(100);
    }
  }

  createConnection(connectionId) {
    const sendJson = JSON.stringify({
      type: "connect",
      connectionId: connectionId,
    });
    console.log(sendJson);
    try {
      this.websocket.send(sendJson);
    } catch (e) {
      console.log(e);
    }
  }

  deleteConnection(connectionId) {
    const sendJson = JSON.stringify({
      type: "disconnect",
      connectionId: connectionId,
    });
    Logger.log(sendJson);
    try {
      this.websocket.send(sendJson);
    } catch (e) {
      console.log(e);
    }
  }

  sendOffer(connectionId, sdp) {
    const data = { sdp: sdp, connectionId: connectionId };
    const sendJson = JSON.stringify({
      type: "offer",
      from: connectionId,
      data: data,
    });
    Logger.log(sendJson);
    try {
      this.websocket.send(sendJson);
    } catch (e) {
      console.log(e);
    }
  }

  sendAnswer(connectionId, sdp) {
    const data = { sdp: sdp, connectionId: connectionId };
    const sendJson = JSON.stringify({
      type: "answer",
      from: connectionId,
      data: data,
    });
    Logger.log(sendJson);
    try {
      this.websocket.send(sendJson);
    } catch (e) {
      console.log(e);
    }
  }

  sendCandidate(connectionId, candidate, sdpMLineIndex, sdpMid) {
    if(this.isWsOpen != true){ return }
    const data = {
      candidate: candidate,
      sdpMLineIndex: sdpMLineIndex,
      sdpMid: sdpMid,
      connectionId: connectionId,
    };
    const sendJson = JSON.stringify({
      type: "candidate",
      from: connectionId,
      data: data,
    });
    Logger.log(sendJson);
    try {
      this.websocket.send(sendJson);
    } catch (e) {
      console.log(e);
    }
  }
}
