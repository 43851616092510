
import styled from 'styled-components'

const  Center = styled.div`
  display: flex;
  justify-content: center;
  align-items : center;
`
const Login = ({ children }) => {
  return (
    <Center>
      {children}
    </Center>
  )
}
export default Login