import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useParams, Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Card,
  Typography,
  Space,
  Modal,
  Radio,
  Input,
  InputNumber,
  Form,
  Timeline,
  Table,
  Popover,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import HelperSelector from "./HelperSelector";
import VideoPlayer from "../components/videosPlayer/VideoPlayer";
import { generateLoginCode } from "./api";
import axios from "axios";
const { Title, Text } = Typography;
const { confirm } = Modal;
const GameController = () => {
  const [ws, setWs] = useState(null);
  const [data, setData] = useState([]);
  const [game, setGame] = useState(1);
  const [room, setRoom] = useState(1);
  const [level, setLevel] = useState(1);
  const [subLevel, setSubLevel] = useState(1);
  const [message, setMessage] = useState({});
  const [isGenerateLoginCode, setIsGenerateLoginCode] = useState(false);

  // useEffect(() => {
  //   const ws = new WebSocket("ws://localhost:5000");
  //   ws.onopen = () => {
  //     console.log("connected");
  //   };
  //   ws.onmessage = (evt) => {
  //     setData((prevData) => {
  //       prevData.push(evt.data);
  //       console.log(prevData);
  //       return prevData;
  //     });
  //   };
  //   setWs(ws);
  //   return () => {
  //     console.log("close");
  //     ws.close();
  //   };
  // }, []);

  const { patientId } = useParams();
  const [patientsData, setPatientsData] = useState([
    { name: "firstname", value: "" },
    { name: "lastname", value: "" },
    { name: "age", value: "" },
    { name: "loginCode", value: "" },
  ]);
  // useEffect(() => {
  //   if (!isGenerateLoginCode) return;
  //   firebase
  //     .firestore()
  //     .collection("patients")
  //     .doc(patientId)
  //     .update({ loginCode: "test" })
  //     .then((docRef) => {
  //       // const newFields = docRef.data();
  //       // setPatientsData({ id: docRef.id, ...newFields });
  //       console.log(docRef);
  //     });
  // }, [isGenerateLoginCode]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .get()
      .then((docRef) => {
        const newFields = docRef.data();
        setPatientsData({ id: docRef.id, ...newFields });
        console.log(newFields);
      });
  }, [patientId]);

  const showPropsConfirm = (message) => {
    confirm({
      title: "Are you sure to change to this level?",
      icon: <ExclamationCircleOutlined />,
      content: JSON.stringify(message),
      okText: "Yes",
      okType: "danger",

      cancelText: "No",
      onOk() {
        setMessage(message);
        // return new Promise((resolve, reject) => {
        //   ws.send(JSON.stringify(message));
        // });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onChangeGame = (e) => {
    setGame(e.target.value);
  };
  const onChangeRoom = (e) => {
    setRoom(e.target.value);
  };
  const onChangeLevel = (e) => {
    setLevel(e.target.value);
  };
  const onChangeSubLevel = (e) => {
    setSubLevel(e.target.value);
  };
  const sendMessage = (message) => {};
  const handleGenerateLogincode = () => {
    setIsGenerateLoginCode(true);
    generateLoginCode(patientId).then((response) => {
      setIsGenerateLoginCode(false);
      const { loginCode } = response.data;
      setPatientsData((prevData) => {
        console.log(patientsData);
        return { ...prevData, loginCode };
      });
    });
  };
  const handleVideoDisconected = () => {
    return axios.post(
      "http://ec2-18-140-218-89.ap-southeast-1.compute.amazonaws.com/logout",
      { patientId }
    );
  };
  const handleAddMMSEScore = ({ score }) => {
    console.log(score);
    firebase
      .firestore()
      .collection("patients")
      .doc(patientId)
      .collection("mmseScores")
      .add({ score, createdAt: Date.now() });
  };
  const handleChangeRoom = () => {
    const message = {
      command: "changeRoom",
      roomId: room,
    };
    showPropsConfirm(message);
  };
  const handleChangeGame = () => {
    const message = {
      command: "changeGame",
      gameId: game,
    };
    showPropsConfirm(message);
  };
  const handleChangeStage = () => {
    const message = {
      command: "changeStage",
      level,
      subLevel,
    };
    showPropsConfirm(message);
  };
  const handleShowHint = () => {
    const message = {
      command: "showHint",
    };
    showPropsConfirm(message);
  };
  const handleBackToLobby = () => {
    const message = {
      command: "backToLobby",
    };
    showPropsConfirm(message);
  };
  const handleReplay = () => {
    const message = {
      command: "replay",
    };
    showPropsConfirm(message);
  };
  const gameOptions = [
    { label: "เกมส์ 1", value: "0" },
    { label: "เกมส์ 2", value: "1" },
    { label: "เกมส์ 3", value: "2" },
    { label: "เกมส์ 4", value: "3" },
  ];
  const roomOptions = [
    { label: "ห้อง 1", value: "0" },
    { label: "ห้อง 2", value: "1" },
    { label: "ห้อง 3", value: "2" },
    { label: "ห้อง 4", value: "3" },
  ];
  const levelOptions = [
    { label: "Tutorial", value: "0" },
    { label: "ระดับ 1", value: "1" },
    { label: "ระดับ 2", value: "2" },
    { label: "ระดับ 3", value: "3" },
    { label: "ระดับ 4", value: "4" },
  ];
  const subLevelOptions = [
    { label: "ระดับ 1", value: "0" },
    { label: "ระดับ 2", value: "1" },
    { label: "ระดับ 3", value: "2" },
    { label: "ระดับ 4", value: "3" },
    { label: "ระดับ 5", value: "4" },
    { label: "ระดับ 6", value: "5" },
    { label: "ระดับ 7", value: "6" },
    { label: "ระดับ 8", value: "7" },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col span={24} md={12}>
          <PatientInfo
            patientsData={patientsData}
            handleGenerateLogincode={handleGenerateLogincode}
            handleAddMMSEScore={handleAddMMSEScore}
            isGenerateLoginCode={isGenerateLoginCode}
          ></PatientInfo>
        </Col>
        <Col span={24} md={12}>
          <HelperSelector
            handleBackToLobby={handleBackToLobby}
            handleShowHint={handleShowHint}
            handleReplay={handleReplay}
          ></HelperSelector>
        </Col>
      </Row>
      <div style={{ marginBottom: "20px" }}>
        <h2>Streaming</h2>
        <Row>
          <VideoPlayer
            handleDisconnected={handleVideoDisconected}
            message={message}
            patientId={patientId}
          ></VideoPlayer>
        </Row>
      </div>
      <Row>
        <Col span={24} md={24} lg={12}>
          <RoomSelector
            options={roomOptions}
            onChange={onChangeRoom}
          ></RoomSelector>
        </Col>

        <Col span={0} md={0} lg={12}></Col>
      </Row>
      <Button type="primary" onClick={handleChangeRoom}>
        Change room
      </Button>
      <Row>
        <Col span={24} md={24} lg={12}>
          <GameSelector
            options={gameOptions}
            onChange={onChangeGame}
          ></GameSelector>
        </Col>
        <Col span={0} md={0} lg={12}></Col>
      </Row>
      <Button type="primary" onClick={handleChangeGame}>
        Change game
      </Button>
      <Row>
        <Col span={12} lg={12}>
          <LevelSelector
            options={levelOptions}
            onChange={onChangeLevel}
          ></LevelSelector>
        </Col>
        <Col span={12} lg={12}>
          <SubLevelSelector
            options={subLevelOptions}
            onChange={onChangeSubLevel}
          ></SubLevelSelector>
        </Col>
      </Row>
      <Button type="primary" onClick={handleChangeStage}>
        Change level
      </Button>
    </React.Fragment>
  );
};
const GameSelector = ({ options, onChange }) => {
  return (
    <Row style={{ minHeight: "10rem" }}>
      <Col span={24}>
        <Title level={2}>เลือกเกมส์ที่ต้องการ</Title>
        <Card>
          <Radio.Group
            options={options}
            optionType="button"
            onChange={onChange}
          ></Radio.Group>
        </Card>
      </Col>
    </Row>
  );
};

const RoomSelector = ({ options, onChange }) => {
  return (
    <Row style={{ minHeight: "10rem" }}>
      <Col span={24}>
        <Title level={2}>เลือกห้องที่ต้องการ</Title>
        <Card>
          <Row justify="space-between">
            <Radio.Group
              options={options}
              optionType="button"
              onChange={onChange}
            ></Radio.Group>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const LevelSelector = ({ options, onChange }) => {
  return (
    <Row style={{ minHeight: "10rem" }}>
      <Col span={24}>
        <Title level={2}>เลือกระดับที่ต้องการ</Title>
        <Card>
          <Row justify="space-between">
            <Radio.Group
              options={options}
              optionType="button"
              onChange={onChange}
            ></Radio.Group>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const SubLevelSelector = ({ options, onChange }) => {
  return (
    <Row style={{ minHeight: "10rem" }}>
      <Col span={24}>
        <Title level={2}>เลือกระดับรองที่ต้องการ</Title>
        <Card>
          <Row justify="space-between">
            <Radio.Group
              options={options}
              optionType="button"
              onChange={onChange}
            ></Radio.Group>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
const PatientInfo = ({
  patientsData,
  isGenerateLoginCode,
  handleGenerateLogincode,
}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRecordsModalVisible, setIsRecordsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [scores, setScores] = useState([]);

  useEffect(() => {
    console.log(isModalVisible);
    if (isModalVisible === false) {
      return;
    }
    firebase
      .firestore()
      .collection("patients")
      .doc(patientsData.id)
      .collection("mmseScores")
      .get()
      .then((snapshot) => {
        let ans = [];
        snapshot.forEach((doc) => {
          ans.push({ id: doc.id, ...doc.data() });
        });
        ans.sort((a, b) => a.createdAt - b.createdAt);
        setData(ans);
      });
  }, [isModalVisible]);

  useEffect(() => {
    console.log(isRecordsModalVisible);
    if (isRecordsModalVisible === false) {
      return;
    }
    console.log(scores);
    firebase
      .firestore()
      .collection("scores")
      .doc(patientsData.id)
      .collection("records")
      .get()
      .then((snapshot) => {
        console.log(snapshot);
        let ans = [];
        snapshot.forEach((doc) => {
          ans.push({ id: doc.id, ...doc.data() });
        });
        // ans.sort((a, b) => a.timestamp - b.timeStart);
        setScores(ans);
      });
  }, [isRecordsModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showRecordsModal = () => {
    setIsRecordsModalVisible(true);
  };

  const handleRecordsOk = () => {
    setIsRecordsModalVisible(false);
  };

  const handleRecordsCancel = () => {
    setIsRecordsModalVisible(false);
  };
  const showPropsConfirm = () => {
    if (!form.getFieldValue("score")) return;
    confirm({
      title: "Are you sure to send score?",
      icon: <ExclamationCircleOutlined />,
      content: `Your score : ${form.getFieldValue("score")}`,
      okText: "Yes",
      okType: "danger",

      cancelText: "No",
      onOk() {
        form.resetFields();
        firebase
          .firestore()
          .collection("patients")
          .doc(patientsData.id)
          .collection("mmseScores")
          .get()
          .then((snapshot) => {
            let ans = [];
            snapshot.forEach((doc) => {
              ans.push({ id: doc.id, ...doc.data() });
            });
            ans.sort((a, b) => a.createdAt - b.createdAt);
            setData(ans);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const mapColor = (actionId) => {
    switch (actionId) {
      case 0:
        return "blue";
        break;
      case 1:
        return "red";
        break;
      case 2:
        return "yellow";
        break;
      case 3:
        return "green";
        break;
      default:
        return "blue";
        break;
    }
  };

  const mapAction = (actionId) => {
    switch (actionId) {
      case 0:
        return "Correct";
        break;
      case 1:
        return "Wrong";
        break;
      case 2:
        return "Hint";
        break;
      case 3:
        return "Finish";
        break;
      default:
        return "action";
        break;
    }
  };
  const content = (record) => (
    <Timeline>
      {record.timeline &&
        record.timeline.map(({ timestamp, action }) => (
          <Timeline.Item color={mapColor(action.type)}>
            {`${timestamp} ${mapAction(action.type)}`}
          </Timeline.Item>
        ))}
    </Timeline>
  );

  const filteredScore = (scores) => {
    return scores.map((score) => {
      const eventCount = score.timeline.length - 1;
      const correct = score.timeline.filter((v) => v.action.type == 0).length;
      const wrong = score.timeline.filter((v) => v.action.type == 1).length;
      const hintUsage = score.timeline.filter((v) => v.action.type == 2).length;

      const diff_minutes = (dt2, dt1) => {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff = Math.abs(diff);
        const diffMin = Math.floor(diff / 60);
        const diffSec = diff - diffMin * 60;
        if (diffMin == 0) {
          return `${diffSec} วินาที`;
        }
        return `${diffMin} นาที ${diffSec} วินาที`;
      };
      let totalTime = "-";
      if (
        score.timeline.length > 0 &&
        score.timeline[score.timeline.length - 1].action.type == 3
      ) {
        totalTime = diff_minutes(
          new Date(score.startTimestamp),
          new Date(score.timeline[score.timeline.length - 1].timestamp)
        );
      }

      return {
        ...score,
        eventCount,
        hintUsage,
        correct,
        wrong,
        totalTime,
      };
    });
  };
  return (
    <Col>
      <Row>
        <Title level={2}>ข้อมูลคนไข้</Title>
        <Row>
          <Link to={`/profile/detail/${patientsData.id}`}>
            <Button>Info</Button>
          </Link>
          <Link to={`/records/${patientsData.id}`}>
            <Button onClick={showRecordsModal}> Records</Button>
          </Link>
        </Row>
      </Row>
      <Card>
        <Space direction="vertical">
          <Text>ชื่อ {patientsData.firstname}</Text>
          <Text>นามสกุล {patientsData.lastname}</Text>
          <Text>อายุ {patientsData.age}</Text>
          <Row>
            <Text>
              Login code{" "}
              {patientsData.loginCode == undefined ||
              patientsData.loginCode == "undefined"
                ? ""
                : patientsData.loginCode}
            </Text>
            <Space direction="horizontal"></Space>
            <Button
              loading={isGenerateLoginCode}
              style={{ marginLeft: "2rem" }}
              onClick={() => {
                handleGenerateLogincode();
              }}
            >
              generate
            </Button>
          </Row>
        </Space>
      </Card>
    </Col>
  );
};

export default GameController;
