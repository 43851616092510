import { Card, Form, Input, DatePicker, Select } from "antd";
const { Option } = Select;
const educations = ["below bechelor", "bachelor", " master", "doctor"];
const detailProfileForm = ({ handleSubmit, patientsData, patientId }) => {
  return (
    <Card>
      <Form
        style={{ color: "rgb(43, 40, 40)" }}
        onFinish={handleSubmit}
        fields={patientsData}
      >
        <Form.Item
          label="Firstname"
          name="firstname"
          rules={[{ required: true, message: "Please input your firstname!" }]}
        >
          <Input
            style={{ color: "rgb(43, 40, 40)" }}
            placeholder="Firstname"
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          label="Lastname"
          name="lastname"
          rules={[{ required: true, message: "Please input your firstname!" }]}
        >
          <Input
            placeholder="Lastname"
            disabled={true}
            style={{ color: "rgb(43, 40, 40)" }}
            rules={[{ required: true, message: "Please input your lastname!" }]}
          />
        </Form.Item>
        <Form.Item
          label="Date Of Birth"
          name="dateOfBirth"
          style={{ color: "rgb(43, 40, 40)" }}
          rules={[
            { required: true, message: "Please input your date of birth!" },
          ]}
        >
          <DatePicker disabled />
        </Form.Item>
        <Form.Item
          label="Identification Number"
          name="identificationNumber"
          rules={[
            {
              required: true,
              message: "Please input your identificationNumber!",
            },
          ]}
        >
          <Input
            disabled
            placeholder="xxxxxxxxxxxxx"
            style={{ color: "rgb(43, 40, 40)" }}
          />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input
            disabled
            placeholder="phoneNumber"
            style={{ color: "rgb(43, 40, 40)" }}
          />
        </Form.Item>
        <Form.Item
          name="education"
          label="Education"
          rules={[{ required: true }]}
        >
          <Select disabled placeholder="Select a option" allowClear>
            {educations.map((education) => {
              return <Option value={education}>{education}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Address" name="address">
          <Input.TextArea
            disabled
            placeholder="address"
            style={{ color: "rgb(43, 40, 40)" }}
          />
        </Form.Item>
        <h2>Contact Person</h2>
        <Form.Item
          label="Firstname"
          name="contactPersonFirstname"
          rules={[{ required: true, message: "Please input your firstname!" }]}
        >
          <Input
            disabled
            placeholder="Firstname"
            style={{ color: "rgb(43, 40, 40)" }}
          />
        </Form.Item>
        <Form.Item
          label="Lastname"
          name="contactPersonLastname"
          rules={[{ required: true, message: "Please input your lastname!" }]}
        >
          <Input
            disabled
            placeholder="Lastname"
            rules={[{ required: true, message: "Please input your lastname!" }]}
            style={{ color: "rgb(43, 40, 40)" }}
          />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="contactPersonPhoneNumber"
          rules={[{ required: true, message: "Please input your age!" }]}
        >
          <Input
            disabled
            placeholder="phoneNumber"
            style={{ color: "rgb(43, 40, 40)" }}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default detailProfileForm;
