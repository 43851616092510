import { Button, Row, Layout } from "antd";

// import firebase from "firebase/app";
// import "firebase/auth";
import { authContext } from "../hooks/useUser";
import { useContext } from "react";
const { Header } = Layout;
const MyHeader = () => {
  const auth = useContext(authContext);
  const onSignOut = () => {
    auth.signout();
    //firebase.auth().signOut().then(router.replace("/login"));
  };
  return (
    <Header
      style={{
        background: "white",
        borderBottom: "1px solid black",
        height: "5vh",
      }}
    >
      <Row span={24} justify="end" align="middle" style={{ height: "100%" }}>
        <Button onClick={() => onSignOut()}>Sign out</Button>
      </Row>
    </Header>
  );
};

export default MyHeader;
